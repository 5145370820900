import React from "react";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils";

const TooltipOrder = ({ order }) => {
  return (
    <>
      <p>
        【時間】
        {dayjs(order.date_start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
        &nbsp;~&nbsp;
        {dayjs(order.date_end, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
      </p>

      <p>【コース】{order.course?.name}</p>
      <p>【指名】 {order.nomination?.name}</p>
      <p>【料金】 {formatCurrency(order.total_cost)}</p>
      <p>【お客様名】 {order.customer_name}</p>
      <p>【電話】 {order.customer_tel}</p>
      <p>【連絡用備考】 {order.contact_remarks}</p>
    </>
  );
};

export default TooltipOrder;
