import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {
  changeCalendarRestTime,
  deleteCalendarRestTime,
} from "../../api/therapist";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  maxWidth: "calc(100% - 40px)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  maxHeight: "100dvh",
  overflowY: "auto",
  paddingBottom: "16px",
};

const ModalRestTime = ({ open, handleClose, item, refetch }) => {
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      id: item.id,
      calendar_id: item.calendar_id,
      time_start: item.time_start
        ? dayjs(item.time_start, "YYYY-MM-DD HH:mm:ss")
        : undefined,
      time_end: item.time_end
        ? dayjs(item.time_end, "YYYY-MM-DD HH:mm:ss")
        : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleDeleteResetTime = async () => {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (item.id && confirm("対応不可時間を解除しますか？")) {
        await deleteCalendarRestTime(item.id);
        handleClose();
        refetch();
        alert("予約をキャンセルしました。");
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleAddRestTime = async (data) => {
    const payload = {
      time_start: data.time_start.format("YYYY-MM-DD HH:mm:ss"),
      time_end: data.time_end.format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      await changeCalendarRestTime(item.id, payload);
      refetch();
      handleClose();
      alert("対応不可時間を追加しました。");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          if (error.response.data.message) {
            const messages = error.response.data.message;
            for (let key in messages) {
              if (key === "time_start") {
                setError("time_start", {
                  type: "manual",
                  message: messages[key],
                });
              }
              if (key === "time_end") {
                setError("time_end", {
                  type: "manual",
                  message: messages[key],
                });
              }
            }
          }
        } else {
          alert("エラーが発生しました。");
        }
      } else {
        alert("エラーが発生しました。");
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        z
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              backgroundColor: "rgb(136, 160, 185)",
              color: "white",
              padding: "16px",
              boxShadow:
                " rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px",
            }}
          >
            休憩時間登録
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <form onSubmit={handleSubmit(handleAddRestTime)}>
              <Grid
                container
                spacing={2}
                alignItems="flex-startz"
                sx={{ padding: "32px" }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Controller
                        name="time_start"
                        control={control}
                        render={({ field }) => (
                          <DateTimePicker
                            label="開始予約時間"
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.time_start?.message}
                      </FormHelperText>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <Controller
                        name="time_end"
                        control={control}
                        render={({ field }) => (
                          <DateTimePicker
                            label="終了予約時間"
                            value={field.value}
                            onChange={(date) => field.onChange(date)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                        )}
                      />
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.time_end?.message}
                      </FormHelperText>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  padding: "0 20px 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  columnGap: "16px",
                }}
              >
                <Button type="submit" variant="contained" color="error">
                  対応不可
                </Button>
                <Button
                  type="button"
                  onClick={handleDeleteResetTime}
                  variant="outlined"
                  color="error"
                >
                  対応不可解除
                </Button>
              </Box>
            </form>
          </LocalizationProvider>
        </Box>
      </Modal>
    </>
  );
};

export default ModalRestTime;
