import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  styled,
  FormHelperText,
  FormControl,
  InputBase,
  FormLabel,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import TablePaginationLayout from "../../layouts/TablePagination";
import { useCallback } from "react";
import NavButton from "../../layouts/NavButton";
import useExtensionMaster from "../../features/extension";
import DeleteIcon from '@mui/icons-material/Delete'
import PopupComponent from "../../layouts/Popup";

const columnTitles = [
  "id",
  "延長名",
  "お客様へ請求する金額",
  "セラピストへ支払われる金額",
  "延長時間",
  "メモ",
  "操作",
];

const CustomButtonEdit = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomButtonDelete = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
}));

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const AccountMasterExtend = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newExtension,
    rowsPerPage,
    total,
    page,
    editExtension,
    extensionToDelete,
    isPopupOpenUpdate,
    isPopupOpenDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    setIsPopupOpenDelete,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddExtension,
    handleUpdateExtension,
    handleDeleteExtension,
    handleFocus,
    handleBlur,
    handleEditClick,
    handleDeleteClick,
    handleClosePopup,
    handleChange
  } = useExtensionMaster()

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const handleSuccess = await handleUpdateExtension(editExtension);
      if (handleSuccess) {
        handleClosePopup();
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleSubmitDelete = useCallback(async () => {
    await handleDeleteExtension(extensionToDelete.id);
    setIsPopupOpenDelete(false);
  }, [extensionToDelete, handleDeleteExtension, setIsPopupOpenDelete]);

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      {isPopupOpenUpdate && <PopupComponent open={isPopupOpenUpdate} onClose={handleClosePopup} >
        <FormControl
          className="MuiFormControl-marginNormal"
          sx={{ margin: "16px 0 8px", width: "100%" }}
          error={!!errorsUpdate.name}
        >
          <FormLabel
            className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
            htmlFor="name"
            id="name-label"
            sx={{
              position: "absolute",
              display: "block",
              transformOrigin: "left top",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              top: 0,
              left: 0,
              color: "rgba(0, 0, 0, 0.6)",
              transform: focusState.name || editExtension.name
                ? "translate(0px, -1.5px) scale(0.75)"
                : "translate(0px, 20px) scale(1)",
              transition:
                "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            }}
          >
            延長名 *
          </FormLabel>
          <InputBase
            className="MuiInput-root MuiInput-underline"
            id="name"
            name="name"
            type="text"
            onFocus={() => handleFocus("name")}
            onBlur={() => handleBlur("name")}
            inputProps={{ className: "MuiInputBase-input css-mnn31" }}
            aria-invalid="false"
            value={editExtension.name}
            onChange={handleChange}
            sx={{
              marginTop: "16px",
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
              {
                borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transition:
                  "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
              {
                borderBottom: "2px solid rgb(0, 0, 0)",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
              {
                borderBottom: "2px solid rgb(25, 118, 210)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transform: "translateX(0px) scaleX(1)",
                transition:
                  "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
            }}
            inputRef={nameInputRef}
          />
          <FormHelperText>{errorsUpdate.name}</FormHelperText>
        </FormControl>

        <FormControl
          className="MuiFormControl-marginNormal"
          sx={{ margin: "16px 0 8px", width: "100%" }}
          error={!!errorsUpdate.cost}
        >
          <InputBase
            className="MuiInput-root MuiInput-underline"
            id="cost"
            name="cost"
            type="number"
            placeholder="お客様へ請求する金額"
            inputProps={{ className: "MuiInputBase-input css-mnn31" }}
            aria-invalid="false"
            value={editExtension.cost}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
            sx={{
              marginTop: "16px",
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
              {
                borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transition:
                  "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
              {
                borderBottom: "2px solid rgb(0, 0, 0)",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
              {
                borderBottom: "2px solid rgb(25, 118, 210)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transform: "translateX(0px) scaleX(1)",
                transition:
                  "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
            }}
          />
          <FormHelperText>{errorsUpdate.cost}</FormHelperText>
        </FormControl>

        <FormControl
          className="MuiFormControl-marginNormal"
          sx={{ margin: "16px 0 8px", width: "100%" }}
          error={!!errorsUpdate.therapist_amount}
        >
          <InputBase
            className="MuiInput-root MuiInput-underline"
            id="therapist_amount"
            name="therapist_amount"
            type="number"
            placeholder="セラピストへ支払われる金額"
            inputProps={{ className: "MuiInputBase-input" }}
            aria-invalid="false"
            value={editExtension.therapist_amount}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
            sx={{
              marginTop: "16px",
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
              {
                borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transition:
                  "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
              {
                borderBottom: "2px solid rgb(0, 0, 0)",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
              {
                borderBottom: "2px solid rgb(25, 118, 210)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transform: "translateX(0px) scaleX(1)",
                transition:
                  "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
            }}
          />
          <FormHelperText>{errorsUpdate.therapist_amount}</FormHelperText>
        </FormControl>

        <FormControl
          className="MuiFormControl-marginNormal"
          sx={{ margin: "16px 0 8px", width: "100%" }}
          error={!!errorsUpdate.extra_time}
        >
          <InputBase
            className="MuiInput-root MuiInput-underline"
            id="extra_time"
            name="extra_time"
            type="number"
            placeholder="セラピストへ支払われる金額"
            inputProps={{ className: "MuiInputBase-input" }}
            aria-invalid="false"
            value={editExtension.extra_time}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
            sx={{
              marginTop: "16px",
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
              {
                borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transition:
                  "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
              {
                borderBottom: "2px solid rgb(0, 0, 0)",
              },
              "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
              {
                borderBottom: "2px solid rgb(25, 118, 210)",
                left: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                right: 0,
                transform: "translateX(0px) scaleX(1)",
                transition:
                  "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                pointerEvents: "none",
              },
            }}
          />
          <FormHelperText>{errorsUpdate.extra_time}</FormHelperText>
        </FormControl>

        <FormControl
          sx={{ width: "100%" }}
          error={!!errorsUpdate.remarks}
        >
          <textarea
            aria-label="minimum height"
            id="edit_remarks"
            name="remarks"
            placeholder="メモ"
            style={{
              width: "100%",
              height: "60px",
              marginTop: 30,
              borderRadius: 5,
              fontSize: 18,
              backgroundColor: "transparent",
              outline: "none",
            }}
            value={editExtension.remarks}
            onChange={handleChange}
          />
          <FormHelperText>{errorsUpdate.remarks}</FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="outlined"
          color="success"
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
        >
          保存
        </Button>
      </PopupComponent>}
      {isPopupOpenDelete && (
        <PopupComponent open={isPopupOpenDelete} onClose={() => setIsPopupOpenDelete(false)}>
          <Typography variant="h4" gutterBottom>
            確認
          </Typography>
          <Typography variant="body1" paragraph>
            削除するデータを選択しています。続行しますか?
          </Typography>

          <Button
            type="button"
            fullWidth
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            style={{ marginTop: "20px" }}
            onClick={handleSubmitDelete}
          >
            消す
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setIsPopupOpenDelete(false)}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            キャンセル
          </Button>
        </PopupComponent>
      )}
      <Container maxWidth="lg">
        <NavButton />
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          延長
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【新規登録】
          </Typography>
          <Paper sx={{ padding: "16px", backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="延長名"
                  name="name"
                  value={newExtension.name}
                  onChange={handleInputChange}
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="お客様へ請求する金額"
                  name="cost"
                  value={newExtension.cost}
                  onChange={handleInputChange}
                  required
                  error={!!errors.cost}
                  helperText={errors.cost}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="セラピストへ支払われる金額"
                  name="therapist_amount"
                  value={newExtension.therapist_amount}
                  onChange={handleInputChange}
                  required
                  error={!!errors.therapist_amount}
                  helperText={errors.therapist_amount}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="延長時間"
                  name="extra_time"
                  value={newExtension.extra_time}
                  onChange={handleInputChange}
                  required
                  error={!!errors.extra_time}
                  helperText={errors.extra_time}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="メモ"
                  name="remarks"
                  value={newExtension.remarks}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <CustomSaveButton
                  variant="outlined"
                  onClick={handleAddExtension}
                >
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録済み一覧】
          </Typography>
          <Paper sx={{ marginTop: "16px", backgroundColor: '#fff', boxShadow: 'none' }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted ? sortColumnDirection : 'asc';

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px", color: '#000' }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      {Object.entries(row).map(([key, value], cellIndex) => (
                        <TableCell key={cellIndex} align="center" sx={{ color: '#000' }}>
                          {value}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <CustomButtonEdit variant="outlined" onClick={() => handleEditClick(row)}>編集</CustomButtonEdit>
                        <CustomButtonDelete variant="outlined" sx={{ marginLeft: 1 }} onClick={() => handleDeleteClick(row)}>削除</CustomButtonDelete>
                      </TableCell>
                    </TableRow>
                  ))}
                  {sortedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default AccountMasterExtend;
