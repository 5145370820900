export const STALE_TIME_DEFAULT = 120000; // 2 minutes
export const MAIN_NOMINATION_COUNT_FORMULA = "1000+parseInt(mainNominationCount/5)*500";

export const PAYMENT_METHOD_CASH = 1;
export const PAYMENT_METHOD_CARD = 2;
export const PAYMENT_METHOD_CARD_CHARGE = 1.1;
export const PAYMENT_METHOD_CARD_FEE = 50;

export const START_HOUR_TIME = 12;
export const END_HOUR_TIME = 29; // 29 is 5am of the next day

export const NONE_REPEAT_TYPE = 0;
export const DAILY_TYPE = 1;
export const EVERY_DAY_OF_THE_WEEK_TYPE = 2;
export const ONE_DAY_IN_EVERY_WEEK_TYPE = 3;

export const ONLY_UPDATE_THIS_EVENT = 1;
export const UPDATE_ALL_FUTURE_EVENTS = 2;
export const UPDATE_ALL_EVENTS = 3;

export const DAY_OF_WEEK_TEXT_LARGE_ARR = ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"];
