import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Button,
  styled,
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputBase,
  FormHelperText,
} from "@mui/material";
import HeaderReservationManagement from "../../layouts/HeaderReservationManagement";
import Navbar from "../../layouts/Navbar";
import { useCallback } from "react";
import TablePaginationLayout from "../../layouts/TablePagination";
import useNominationMaster from "../../features/nomination";
import PopupComponent from "../../layouts/Popup";
import DeleteIcon from "@mui/icons-material/Delete";
import NavButton from "../../layouts/NavButton";
import { MAIN_NOMINATION_COUNT_FORMULA } from "../../constants";


const columnTitles = [
  "id",
  "指名の種類",
  "お客様へ請求する金額",
  "セラピストへ支払われる金額",
  "本指名",
  "メモ",
  "プレビュー",
  "操作",
];

const CustomButtonEdit = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const CustomButtonDelete = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  color: theme.palette.error.main,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.error.light,
    borderColor: theme.palette.error.main,
  },
}));

const CustomSaveButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  backgroundColor: 'transparent',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.main,
  },
}));

const AccountMasterAssign = () => {
  const {
    activeSortColumn,
    sortColumnDirection,
    sortedData,
    newNomination,
    rowsPerPage,
    total,
    page,
    editNomination,
    isPopupOpenDelete,
    isPopupOpenUpdate,
    nominationToDelete,
    focusState,
    nameInputRef,
    errors,
    errorsUpdate,
    handleRowsPerPageChange,
    handlePageChange,
    handleSort,
    handleInputChange,
    handleAddNomination,
    handleToggleChange,
    handleToggle,
    handleUpdateNomination,
    handleDeleteNomination,
    handleFocus,
    handleBlur,
    handleClosePopup,
    handleChange,
    handleEditClick,
    handleDeleteClick,
    setIsPopupOpenDelete,
    handleCheckboxNewBackground,
    handleCheckboxEditBackground,
    handleUpdateToggleIsMain,
    handleToggleIsMain,
  } = useNominationMaster();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const handleSuccess = await handleUpdateNomination(editNomination);
      if (handleSuccess) {
        handleClosePopup();
      }
    } catch (error) {
      alert("エラーが発生しました。");
    }
  };

  const handleSubmitDelete = useCallback(async () => {
    await handleDeleteNomination(nominationToDelete.id);
    setIsPopupOpenDelete(false);
  }, [nominationToDelete, handleDeleteNomination, setIsPopupOpenDelete]);

  return (
    <>
      <HeaderReservationManagement />
      <Navbar />
      {isPopupOpenUpdate && (
        <PopupComponent open={isPopupOpenUpdate} onClose={handleClosePopup}>
          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.name}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="name"
              id="name-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.name || editNomination.name
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
              指名名 *
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="name"
              name="name"
              type="text"
              onFocus={() => handleFocus("name")}
              onBlur={() => handleBlur("name")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editNomination.name}
              onChange={handleChange}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                {
                  borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transition:
                    "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                {
                  borderBottom: "2px solid rgb(0, 0, 0)",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                {
                  borderBottom: "2px solid rgb(25, 118, 210)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transform: "translateX(0px) scaleX(1)",
                  transition:
                    "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
              }}
              inputRef={nameInputRef}
            />
            <FormHelperText>{errorsUpdate.name}</FormHelperText>
          </FormControl>

          <FormControl
            className="MuiFormControl-marginNormal"
            sx={{ margin: "16px 0 8px", width: "100%" }}
            error={!!errorsUpdate.cost}
          >
            <FormLabel
              className="MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-colorPrimary"
              htmlFor="cost"
              id="cost-label"
              sx={{
                position: "absolute",
                display: "block",
                transformOrigin: "left top",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                top: 0,
                left: 0,
                color: "rgba(0, 0, 0, 0.6)",
                transform:
                  focusState.cost || editNomination.cost
                    ? "translate(0px, -1.5px) scale(0.75)"
                    : "translate(0px, 20px) scale(1)",
                transition:
                  "color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
              }}
            >
            </FormLabel>
            <InputBase
              className="MuiInput-root MuiInput-underline"
              id="cost"
              name="cost"
              type="number"
              placeholder="お客様へ請求する金額"
              onFocus={() => handleFocus("cost")}
              onBlur={() => handleBlur("cost")}
              inputProps={{ className: "MuiInputBase-input css-mnn31" }}
              aria-invalid="false"
              value={editNomination.cost}
              onChange={handleChange}
              onKeyDown={(e) => {
                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+" ||
                  e.key === "."
                ) {
                  e.preventDefault();
                }
              }}
              sx={{
                marginTop: "16px",
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl::before":
                {
                  borderBottom: "1px solid rgb(0, 0, 0,0.42)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transition:
                    "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover::before":
                {
                  borderBottom: "2px solid rgb(0, 0, 0)",
                },
                "&.MuiInputBase-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-focused::after":
                {
                  borderBottom: "2px solid rgb(25, 118, 210)",
                  left: 0,
                  bottom: 0,
                  content: '""',
                  position: "absolute",
                  right: 0,
                  transform: "translateX(0px) scaleX(1)",
                  transition:
                    "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                  pointerEvents: "none",
                },
              }}
            />
            <FormHelperText>{errorsUpdate.cost}</FormHelperText>
          </FormControl>

          <Grid item sx={{ display: "flex", marginTop: "20px" }}>
            <FormControlLabel
              control={<Switch checked={editNomination.therapist_amount !== null} onChange={handleToggle} />}
              label="スライド"
              sx={{
                flexDirection: 'row-reverse',
                marginLeft: 0
              }}
            />
            {editNomination.therapist_amount !== null ? (
              <TextField
                fullWidth
                label="本指名料"
                name="therapist_amount"
                value={editNomination.therapist_amount || ""}
                onChange={handleChange}
                required
                sx={{ marginLeft: 2 }}
                error={!!errorsUpdate.therapist_amount}
                helperText={errorsUpdate.therapist_amount}
              />
            ) : <Typography>1,000円～本指名５本ごとに500円アップ</Typography>}
          </Grid>

          <Grid container xs={12} spacing={2} direction="row" style={{ alignItems: 'center', marginTop: 20 }}>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <label htmlFor="color">色</label>
              <input
                type="color"
                id="color"
                name="color"
                value={editNomination.color}
                onChange={handleChange}
                style={{ width: '100px', height: '50px' }}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <label htmlFor="background">
                <span>背景色</span>
                <input type="checkbox" checked={editNomination.background !== null} onChange={handleCheckboxEditBackground} />
              </label>
              {editNomination.background ? <input
                id="background"
                type="color"
                name="background"
                value={editNomination.background}
                onChange={handleChange}
                style={{ width: '100px', height: '50px' }}
              /> : <div style={{ width: '100px', height: '50px' }} />}
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <span
                style={{
                  color: editNomination.color,
                  background: editNomination.background ? editNomination.background : 'none',
                  borderColor: editNomination.background ? editNomination.background : editNomination.color,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  padding: '4px 8px',
                  borderRadius: '5px',
                }}
              >
                {editNomination.name || '指名の名前'}
              </span>
            </Grid>
          </Grid>

          <FormControl item sx={{ width: "100%", alignItems: "flex-start", marginTop: '20px' }}>
            <FormControlLabel
              control={<Switch checked={editNomination.is_main} onChange={handleUpdateToggleIsMain} />}
              label="本指名"
              sx={{
                marginLeft: 0
              }}
            />
          </FormControl>

          <FormControl
            sx={{ width: "100%" }}
            error={!!errorsUpdate.remarks}
          >
            <textarea
              aria-label="minimum height"
              id="edit_remarks"
              name="remarks"
              placeholder="メモ"
              style={{
                width: "100%",
                height: "60px",
                marginTop: 30,
                borderRadius: 5,
                fontSize: 18,
                backgroundColor: "transparent",
                outline: "none",
              }}
              value={editNomination.remarks}
              onChange={handleChange}
            />
            <FormHelperText>{errorsUpdate.remarks}</FormHelperText>
          </FormControl>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="success"
            style={{ marginTop: 20 }}
            onClick={handleSubmit}
          >
            保存
          </Button>
        </PopupComponent>
      )}
      {isPopupOpenDelete && (
        <PopupComponent
          open={isPopupOpenDelete}
          onClose={() => setIsPopupOpenDelete()}
        >
          <Typography variant="h4" gutterBottom>
            確認
          </Typography>
          <Typography variant="body1" paragraph>
            削除するデータを選択しています。続行しますか?
          </Typography>

          <Button
            type="button"
            fullWidth
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="error"
            style={{ marginTop: "20px" }}
            onClick={handleSubmitDelete}
          >
            消す
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setIsPopupOpenDelete(false)}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            キャンセル
          </Button>
        </PopupComponent>
      )}
      <Container maxWidth="lg">
        <NavButton />
        <Typography
          component="p"
          sx={{
            fontSize: {
              xs: 20,
              md: 28,
            },
            color: "#fff",
            top: "104px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: "24px",
            letterSpacing: "3px",
            margin: "80px 0px 16px",
            textDecoration: "none",
            width: "100%",
            paddingBottom: "15px",
            borderBottom: "2px solid white",
            textAlign: "left",
          }}
        >
          指名
        </Typography>
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【新規登録】
          </Typography>
          <Paper sx={{ padding: "16px", backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="指名の種類"
                  name="name"
                  value={newNomination.name}
                  onChange={handleInputChange}
                  required
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="お客様へ請求する金額"
                  name="cost"
                  value={newNomination.cost}
                  onChange={handleInputChange}
                  required
                  type="number"
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "E" ||
                      e.key === "-" ||
                      e.key === "+" ||
                      e.key === "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                  error={!!errors.cost}
                  helperText={errors.cost}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box' }}>
                <FormControlLabel
                  control={<Switch checked={newNomination.therapist_amount !== null} onChange={handleToggleChange} />}
                  label={<span style={{ whiteSpace: "nowrap" }}>スライド</span>}
                  sx={{
                    flexDirection: 'row-reverse',
                    marginLeft: 0
                  }}
                />
                {newNomination.therapist_amount !== null ? (
                  <TextField
                    fullWidth
                    label="セラピストへ支払われる金額"
                    name="therapist_amount"
                    value={newNomination.therapist_amount}
                    onChange={handleInputChange}
                    required
                    type="number"
                    onKeyDown={(e) => {
                      if (
                        e.key === "e" ||
                        e.key === "E" ||
                        e.key === "-" ||
                        e.key === "+" ||
                        e.key === "."
                      ) {
                        e.preventDefault();
                      }
                    }}
                    sx={{ marginLeft: 2 }}
                    error={!!errors.therapist_amount}
                    helperText={errors.therapist_amount}
                  />
                ) : <Typography>1,000円～本指名５本ごとに500円アップ</Typography>}
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', boxSizing: 'border-box' }}>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <label htmlFor="color">色</label>
                  <input
                    type="color"
                    id="color"
                    name="color"
                    value={newNomination.color}
                    onChange={handleInputChange}
                    style={{ width: '100px', height: '50px' }}
                  />
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <label htmlFor="background">
                    <span>背景色</span>
                    <input type="checkbox" checked={newNomination.background !== null} onChange={handleCheckboxNewBackground} />
                  </label>
                  {newNomination.background !== null ? <input
                    type="color"
                    id="background"
                    name="background"
                    value={newNomination.background}
                    onChange={handleInputChange}
                    style={{ width: '100px', height: '50px' }}
                  /> : <div style={{ width: '100px', height: '50px' }} />}
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <span
                    style={{
                      color: newNomination.color,
                      background: newNomination.background ? newNomination.background : 'none',
                      borderColor: newNomination.background ? newNomination.background : newNomination.color,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '4px 8px',
                      borderRadius: '5px',
                    }}
                  >
                    {newNomination.name || '指名の名前'}
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <FormControlLabel
                  control={<Switch checked={newNomination.is_main} onChange={handleToggleIsMain} />}
                  label="本指名"
                  sx={{
                    marginLeft: 0
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="メモ"
                  name="remarks"
                  value={newNomination.remarks}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  error={!!errors.remarks}
                  helperText={errors.remarks}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <CustomSaveButton
                  variant="outlined"
                  onClick={handleAddNomination}
                >
                  保存
                </CustomSaveButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box sx={{ marginTop: "40px", maxWidth: "100%" }}>
          <Typography
            component="p"
            sx={{
              fontSize: {
                xs: 20,
                md: 28,
              },
              color: "#fff",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "24px",
              letterSpacing: "3px",
              margin: "80px 0px 16px",
              textDecoration: "none",
              width: "100%",
              textAlign: "left",
            }}
          >
            【登録済み一覧】
          </Typography>
          <Paper sx={{ marginTop: "16px", backgroundColor: '#fff', boxShadow: 'none' }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    {columnTitles.map((title, index) => {
                      const isSorted = activeSortColumn === index;
                      const sortDirection = isSorted ? sortColumnDirection : 'asc';

                      return (
                        <TableCell
                          key={index}
                          align="center"
                          sx={{ padding: "8px 16px", color: '#000' }}
                          sortDirection={isSorted ? sortDirection : false}
                        >
                          <TableSortLabel
                            active={isSorted}
                            direction={sortDirection}
                            onClick={() => handleSort(index)}
                          >
                            {title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, rowIndex) => (
                    <TableRow key={rowIndex} className="MuiTableRow-hover">
                      {Object.entries(row).map(([key, value], cellIndex) => key !== 'color' && key !== 'background' && (
                        <TableCell key={cellIndex} align="center" sx={{ color: '#000' }}>
                          {key === "therapist_amount"
                            ? value === null
                              ? MAIN_NOMINATION_COUNT_FORMULA
                              : value
                            : value}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        <span
                          style={{
                            color: row.color,
                            background: row.background ? row.background : 'none',
                            borderColor: row.background ? row.background : row.color,
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            padding: '4px 8px',
                            borderRadius: '5px',
                          }}
                        >
                          {row.name || '指名の名前'}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <CustomButtonEdit variant="outlined" onClick={() => handleEditClick(row)}>編集</CustomButtonEdit>
                        <CustomButtonDelete variant="outlined" sx={{ marginLeft: 1 }} onClick={() => handleDeleteClick(row)}>削除</CustomButtonDelete>
                      </TableCell>
                    </TableRow>
                  ))}
                  {sortedData.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columnTitles.length} align="center">
                        <Typography>該当データがありません。</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationLayout
              rowsPerPage={rowsPerPage}
              total={total}
              page={page}
              rowsPerPageOptions={[50, 100, 125]}
              onPageChange={handlePageChange}
              handleChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default AccountMasterAssign;
