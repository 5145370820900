import { sendGet, sendPost, sendPut } from "./axios";

export const getListStaffSchedule = (params) =>
  sendGet("/staff-schedule", params);

export const createStaffSchedule = (params) =>
  sendPost("/staff-schedule", params);

export const updateStaffSchedule = (id, params) =>
  sendPut(`/staff-schedule/${id}`, params);

export const deleteStaffSchedule = (id, params) => sendPost(`/staff-schedule/delete/${id}`, params);

export const deleteMultipleStaffSchedule = (params) =>
  sendPost("/staff-schedule/delete-multiple", params);
