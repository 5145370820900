import { Box, Button, Checkbox, Modal, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import TimePickerComponent from "../../pages/TherapistShift/TimePickerComponent";
import dayjs from "dayjs";
import moment from "moment";
import { saveTherapistCalendar } from "../../api/therapist";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  overflow: "hidden",
  paddingBottom: "16px",
};

const ModalAddMultiShift = ({
  open,
  handleClose,
  dateSelected = dayjs(),
  stores,
  therapist,
  refetch,
}) => {
  const [timeStart, setTimeStart] = React.useState(moment("11:00", "HH:mm"));
  const [timeEnd, setTimeEnd] = React.useState(moment("11:00", "HH:mm"));
  const [storeId, setStoreId] = React.useState();
  const [daysSelected, setDaysSelected] = React.useState(Array(10).fill(false));

  const days = useMemo(() => {
    const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];

    const getJapaneseDayOfWeek = (date) => {
      return daysOfWeek[dayjs(date).day()];
    };

    return Array.from({ length: 10 }, (_, i) => {
      const date = dateSelected.add(i, "day");
      return date.format("MM/DD") + `(${getJapaneseDayOfWeek(date)})`;
    });
  }, [dateSelected]);

  const handleAddMultiShift = useCallback(async () => {
    if (!storeId) {
      alert("店舗を選択してください");
      return;
    }

    const selectedDays = [];
    for (let i = 0; i < daysSelected.length; i++) {
      if (daysSelected[i]) {
        const date = dateSelected.add(i, "day");
        selectedDays.push(date.format("YYYY-MM-DD"));
      }
    }

    const workingDays = [];
    selectedDays.forEach((day) => {
      const dateTimeStart = moment(day + " " + timeStart.format("HH:mm"));
      const dateTimeEnd = moment(day + " " + timeEnd.format("HH:mm"));
      if (dateTimeStart.isAfter(dateTimeEnd)) {
        dateTimeEnd.add(1, "day");
      }

      workingDays.push({
        status: 1,
        store_id: storeId,
        date_start: dateTimeStart.format("YYYY-MM-DD HH:mm"),
        date_end: dateTimeEnd.format("YYYY-MM-DD HH:mm"),
        date: day,
      });
    });

    try {
      const params = { working_days: workingDays };
      const response = await saveTherapistCalendar(therapist.id, params);
      if (response.success) {
        refetch();
        handleClose();
        alert("登録しました。");
      } else {
        alert("エラーが発生しました。");
      }
    } catch (error) {
      if (error.response?.status === 422) {
        const messages = error.response.data.message;
        if (messages[Object.keys(messages)[0]]) {
          alert(messages[Object.keys(messages)[0]]);
        } else {
          alert("エラーが発生しました。");
        }
      } else {
        alert("エラーが発生しました。");
      }
    }
  }, [
    dateSelected,
    daysSelected,
    storeId,
    timeStart,
    timeEnd,
    therapist,
    refetch,
    handleClose,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "16px 20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              一括入力テンプレート
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "16px",
              gap: "4px",
              width: "94px",
            }}
          >
            <TimePickerComponent value={timeStart} onChange={setTimeStart} />
            <TimePickerComponent value={timeEnd} onChange={setTimeEnd} />
            <select
              className="select-store"
              value={storeId}
              onChange={(e) => setStoreId(e.target.value)}
            >
              <option value="">選択してく</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.store_name}
                </option>
              ))}
            </select>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              適用する日
            </Typography>
            <Box sx={{ display: "flex", gap: "4px" }}>
              {days.map((day, index) => (
                <Box
                  key={day}
                  sx={{
                    display: "flex",
                    gap: "4px",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    fontSize={14}
                    sx={{ width: "62px" }}
                    align="center"
                  >
                    {day}
                  </Typography>
                  <Checkbox
                    checked={dateSelected[index]}
                    onChange={(e) => {
                      const newDaysSelected = [...daysSelected];
                      newDaysSelected[index] = e.target.checked;
                      setDaysSelected(newDaysSelected);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "center",
              gap: "4px",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="success"
              onClick={handleAddMultiShift}
            >
              登録
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              キャンセル
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalAddMultiShift;
